import React, { ReactElement } from "react";

import { Btn, LBtn, IconWrapper } from "./Button.styles";

export enum ButtonType {
  primary = "primary",
  secondary = "secondary",
  tertiary = "tertiary",
}

export interface ILinkButton extends HTMLLinkElement {
  link: string;
  icon?: ReactElement;
}

export interface IButton extends HTMLButtonElement {
  icon?: ReactElement;
  link?: string;
}

export const LinkButton = ({ children, link, icon, ...props }: ILinkButton) => {
  const hasIcon = !!icon;

  return (
    <LBtn href={link} target="_blank" role="button" {...props}>
      <>
        <span>{">"} </span>
        {!!hasIcon && <IconWrapper>{icon}</IconWrapper>}
        {children}
      </>
    </LBtn>
  );
};

export const Button = ({ children, icon, ...props }: IButton) => {
  const hasIcon = !!icon;

  if (props?.link) {
    return <LinkButton {...props}>{children}</LinkButton>;
  }

  return (
    <Btn {...props}>
      <span>{">"} </span>
      {!!hasIcon && <IconWrapper>{icon}</IconWrapper>}
      {children}
    </Btn>
  );
};
