export const fontFamilyTitle = 'courier, sans-serif'
export const fontFamilyStyle = 'courier, monospace';
export const fontFamilyBody = 'courier, monospace';

export const colors = {
  dark: "#111",
  white: "#eee",
  grade: {
    blue: "#0bc",
    green: "#0f0",
    pink: "#ff0096",
    grey: "#888888",
    yellow: "#fade07",
    red: "#cf2011",
    purple: "#5e3dff"
  },
  main: {
    primary: "#d53a9d",
    secondary: "#743ad5",
    tertiary: "#00a9ff",
  },
}

export const borderStyle = (size = 3) => `${size}px solid ${colors.dark}`

export const shadowStyle = (distance = 5) => `${distance}px ${distance}px 0 ${colors.dark}`

export const toSubscribeLink =
  "https://www.sympla.com.br/react-conf-brasil-2020__910432"

export const storeLink = "http://loja.reactconf.com.br/"

export const liveLink = "https://youtu.be/1aZZEO9Jhng"