
export default {
  enabled: true,
  data: [
    {
      name: "Zeno Rocha",
      talk: "Lançamento oficial do React.email",
      videoId: "rQFupuVA5XQ",
      picture: null,
    },
    {
      name: "Frank Javier",
      talk: "Remix",
      videoId: "aI0qTOdAJWo",
      picture: null,
    },
    {
      name: "Felipe Battistotti",
      talk: "Layouts RFC Next.js",
      videoId: "o2JAyHAyp-o",
      picture: null,
    },
    {
      name: "Andrei Calazans",
      talk: "Medindo performance no React Native",
      videoId: "daA1PfRE0xM",
      picture: null,
    },
    {
      name: "Laura Beatris",
      talk: "Transitions e Data Fetching com Suspense API",
      videoId: "6wTjbkxu3ok",
      picture: null,
    },
    {
      name: "Antonio Gally",
      talk: "Documentação: boas práticas, padrões e ferramentas",
      videoId: "muA4dCPXogI",
      picture: null,
    },
    {
      name: "Carlos Ziegler",
      talk: "Vite + Vitest em sua aplicação React",
      videoId: "PH36jWxO5XM",
      picture: null,
    },
    {
      name: "Guilherme Rodz",
      talk: "tRPC no Next.js",
      videoId: "E3RCOYd0QqU",
      picture: null,
    },
    {
      name: "Diego Haz",
      talk: "Componentes acessíveis com Ariakit",
      videoId: "Z9rghkab-8A",
      picture: null,
    },
    {
      name: "Gabriel Eloy",
      talk: "Next.JS + React Native utilizando base única de código",
      videoId: "7a-iFpojYtM",/*placeholder*/
      picture: null,
    },
    {
      name: "Gabriel Daltoso",
      talk: "Dicas de um mantenedor Open Source",
      videoId: "muA4dCPXogI",
      picture: null,
    },
    {
      name: "Nicolas B./ Paulo E.",
      talk: "Monorepos em larga escala",
      videoId: "8ROGKHKovTs",
      picture: null,
    },
    {
      name: "Talysson C.",
      talk: "7 regras para abstrair gerência de estado com hooks",
      videoId: "qmczXuCWT-Y",
      picture: null,
    },
  ],
};
