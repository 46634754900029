import React, { FC, ReactElement, useEffect, useRef } from "react";
import { useState } from "react";
import { sessions } from "@contents";
import { Footer } from "../Footer";
import scrollTo from "gatsby-plugin-smoothscroll";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io"

import {
  MenuContainer,
  MenuBar,
  MenuButton,
  MenuDate,
  MenuItem,
  MenuWrapper,
  FooterContainer,
  MenuLinkContent,
} from "./Menu.styles";

export const Menu: FC<{ handleMenu?: () => void; isOpenMenu?: boolean }> =
  (): ReactElement => {
    const menuRef = useRef();

    const [isOpenMenu, setIsOpenMenu] = useState(false);
    const [isOpenFooter, setIsOpenFooter] = useState(false);

    const handleClick = (e) => {
      //@ts-ignore
      if (menuRef.current.contains(e.target)) {
        // inside click
        return;
      }
      // outside click
      setIsOpenMenu(false);
      setIsOpenFooter(false);
    };

    const handleMenu = (e) => {
      e.stopPropagation();
      setIsOpenMenu(!isOpenMenu);
    };

    const handleFooter = (e) => {
      e.stopPropagation();
      setIsOpenFooter(!isOpenFooter);
    };

    const closeMenu = () => {
      setIsOpenMenu(false);
      setIsOpenFooter(false);
    };

    const clickToScroll = (id: string) => {
      scrollTo(id);
      setIsOpenMenu(false);
    };

    useEffect(() => {
      document.addEventListener("mousedown", handleClick);

      return () => {
        document.removeEventListener("mousedown", handleClick);
      };
    }, []);

    return (
      <div ref={menuRef}>
        {isOpenMenu ? (
          <MenuContainer>
            {sessions.home && (
              <MenuItem onClick={() => clickToScroll("#home")}>inicio</MenuItem>
            )}
            {sessions.conference && (
              <MenuItem onClick={() => clickToScroll("#evento")}>
                o evento
              </MenuItem>
            )}
            {sessions.speakers && (
              <MenuItem onClick={() => clickToScroll("#palestrantes")}>
                palestrantes
              </MenuItem>
            )}
            {sessions.lineup && (
              <MenuItem onClick={() => clickToScroll("#lineup")}>
                lineup
              </MenuItem>
            )}
            {sessions.callforpapers && (
              <MenuItem onClick={() => clickToScroll("#c4p")}>c4p</MenuItem>
            )}
            {sessions.sponsors && (
              <MenuItem onClick={() => clickToScroll("#patrocinio")}>
                patrocinadores
              </MenuItem>
            )}
            {sessions.community && (
              <MenuItem onClick={() => clickToScroll("#comunidade")}>
                comunidade
              </MenuItem>
            )}
            {sessions.contact && (
              <MenuItem onClick={() => clickToScroll("#contato")}>
                contato
              </MenuItem>
            )}
          </MenuContainer>
        ) : null}

        {isOpenFooter && sessions.footer ? (
          <FooterContainer>
            <Footer />
          </FooterContainer>
        ) : null}

        <MenuBar onClick={closeMenu}>
          <MenuWrapper>
            <MenuButton onClick={handleMenu}>Menu</MenuButton>
            {sessions.tickets && (
              <MenuLinkContent
                href="https://www.sympla.com.br/react-conf-brasil-2021__1303484"
                target="blank"
              >
                <MenuButton color="red">
                  Ingressos
                </MenuButton>
              </MenuLinkContent>
            )}
            {sessions.live && (
              <MenuLinkContent
                href="https://youtu.be/g7nyrdwRIFc"
                target="blank"
              >
                <MenuButton color="red" extended>
                Assista por aqui
                </MenuButton>
              </MenuLinkContent>
            )}
          </MenuWrapper>
          <MenuDate onClick={handleFooter}>{!isOpenFooter ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}</MenuDate>
        </MenuBar>
      </div>
    );
  };