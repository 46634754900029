import styled from "styled-components";

import { colors } from "@globals";

export const TitleContainer = styled.h1`
  text-align: left;
  width: 100%;
  text-transform: lowercase;
  font-size: 1.2rem;

  span:first-child {
    color: ${colors.grade.green};
  }

  span:nth-child(2) {
    color: ${colors.grade.red};
  }

  span:last-child {
    color: ${colors.grade.blue};
  }
`;