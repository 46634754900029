import React, {useEffect, useState} from 'react';

import {Container} from './TypingEffect.styles';

interface IProps {
    index: number;
    speed?: number;
    cursor?: boolean;
}

let last_page_loaded = "";
if (typeof window !== 'undefined') {
    last_page_loaded = localStorage.getItem('last_page_loaded')??'';
    localStorage.setItem('last_page_loaded', Date.now() + "");
}

export const TypingEffect: React.FC<IProps> = ({
                                                   index,
                                                   cursor = false,
                                                   speed = 800,
                                                   children
                                               }) => {
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        setIsLoaded(Number(last_page_loaded) + 60000 > Date.now());
    });

    if (isLoaded) {
        return (
            <Container showCursor={cursor} delay={0} speed={300}>
            <div>
                <div className="typewriter">
                    {children}
                </div>
            </div>
            </Container>
        );
    } else {
        return (
            <Container showCursor={cursor} delay={index === 0 ? 0 : index * speed} speed={speed}>
                <div>
                    <div className="typewriter">
                        {children}
                    </div>
                </div>
            </Container>
        );
    }
}
