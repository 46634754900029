import styled from "styled-components";
import { conference } from "@contents";

import {
  borderStyle,
  colors,
  shadowStyle,
} from "@globals";
import {
  BiggerThanSmall,
  SmallScreen,
} from "@style/breakpoints";

export const MenuContainer = styled.div`
  width: 320px;
  margin: 0;
  bottom: 40px;
  position: fixed;
  border-bottom: ${borderStyle()};
  display: flex;
  color: ${colors.dark};
  background-color: ${colors.white};
  padding: 0.5rem 2rem 0.5rem 1rem;
  font-size: 2rem;
  border: ${borderStyle()};
  flex-direction: column;
  padding-bottom: 3rem;
  padding-top: 2rem;
  justify-content: flex-start;
`;

export const FooterContainer = styled.div`
  width: 320px;
  margin: 0;
  bottom: 0;
  right: 0;
  position: fixed;
  border-bottom: ${borderStyle()};
  display: flex;
  color: ${colors.dark};
  background-color: ${colors.white};
  padding: 0.5rem 2rem 0.5rem 1rem;
  font-size: 2rem;
  border: ${borderStyle()};
  flex-direction: column;
  padding-bottom: calc(40px + 3rem);
  padding-top: 2rem;
  justify-content: flex-start;
`;

export const MenuItem = styled.div`
  width: 100%;
  text-transform: uppercase;
  padding: 1% 3%;
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

export const MenuBar = styled.div`
  width: 100vw;
  height: 40px;
  margin: 0;
  position: fixed;
  bottom: 0;
  border-bottom: ${borderStyle()};
  display: flex;
  justify-content: space-between;
  color: ${colors.dark};
  background-color: ${colors.white};
  padding: 0.5rem 2rem 0.5rem 1rem;
  font-size: 2rem;
  border: ${borderStyle()};
`;

export const MenuButton = styled.button`
  width: ${({ extended }) => (extended ? "auto" : "120px")};
  padding-left: 2rem;
  padding-right: 2rem;
  font-weight: bold;
  text-transform: uppercase;
  border: ${borderStyle(2)};
  box-shadow: ${shadowStyle(2)};
  border-radius: 5px;
  margin-right: 10px;
  height: 100%;
  background-color: ${({ color }) => (color ? colors.grade[color] : "none")};
  cursor: pointer;

  a {
    text-decoration: none;
    color: ${colors.dark};
  }

  &:hover {
    filter: brightness(0.8);
  }

  &:active {
    box-shadow: ${shadowStyle(-2)};
  }
`;

export const MenuLinkContent = styled.a`
  cursor: pointer;
  height: 100%;
  margin: 0;
  padding: 0;
  line-height: 0%;

  text-decoration: none;
  color: ${colors.dark};
`;

export const MenuDate = styled.div`
  display: flex;
  border: ${borderStyle(2)};
  box-shadow: ${shadowStyle(-1)};
  background-color: rgba(0, 0, 0, 0.15);
  cursor: pointer;
  margin-right: 2rem;
  padding-right: 1rem;
  padding-left: 1rem;
  justify-content: center;
  align-items: center;

  svg {
    margin: auto;
  }

  ${BiggerThanSmall} {
    margin-right: 3rem;
    padding-right: 1rem;
    padding-left: 2rem;

    /* svg {
      display: none;
    } */

    &::before {
      content: "${conference.dateNumber} ";
    }
  } ;
`;

export const MenuWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  margin: 0;
`;
