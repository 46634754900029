import React, { ReactElement } from "react"

import { colors } from "@globals"

export const logoPaths = (dark?: boolean, size = 150): ReactElement[] => [
  <svg
    key="logo-1"
    width={size}
    height={size}
    viewBox="265 220 320 320"
    x="0px"
    y="0px"
  >
    <g id="react1" className="icon active">
      <path
        fill={dark ? colors.dark : colors.white}
        d="M428.4 337.6c29.9 0 57.9 4.5 78.8 12.7 18 7.1 28.8 16.3 28.8 24.6s-10.8 17.6-28.8 24.6c-20.9 8.2-48.9 12.7-78.8 12.7s-57.9-4.5-78.8-12.7c-18-7.1-28.8-16.3-28.8-24.6s10.8-17.6 28.8-24.6c20.9-8.1 48.9-12.7 78.8-12.7m0-8c-63.8 0-115.6 20.3-115.6 45.4s51.7 45.4 115.6 45.4S544 400.1 544 375s-51.8-45.4-115.6-45.4z"
      />
      <path
        fill={dark ? colors.dark : colors.white}
        d="M467.7 352.3c31.9 55.3 40.2 110.2 18.5 122.8-21.7 12.5-65.2-22.1-97.1-77.4"
      />
      <path
        fill={colors.main.tertiary}
        d="M476 280.4c2.4 0 4.4.5 6.1 1.5 7.2 4.2 9.8 18.1 6.9 37.2-3.3 22.2-13.4 48.7-28.4 74.6-28.5 49.3-62.5 76-80 76-2.4 0-4.4-.5-6.1-1.5-7.2-4.2-9.8-18.1-6.9-37.2 3.3-22.2 13.4-48.7 28.4-74.6 28.5-49.4 62.5-76 80-76m0-8c-23.3 0-59.4 32.3-86.9 80-31.9 55.3-40.2 110.2-18.5 122.8 3 1.7 6.4 2.6 10.1 2.6 23.3 0 59.4-32.3 86.9-80 31.9-55.3 40.2-110.2 18.5-122.8-2.9-1.8-6.4-2.6-10.1-2.6z"
      />
      <path
        fill={dark ? colors.dark : colors.white}
        d="M487.3 350.5c-3.4 0-6.5-1.8-8.2-4.8-2.6-4.5-1.1-10.4 3.5-13 1.4-.8 3.1-1.3 4.7-1.3 3.4 0 6.5 1.8 8.2 4.8 1.3 2.2 1.6 4.8.9 7.2-.7 2.5-2.2 4.5-4.4 5.8-1.4.8-3.1 1.3-4.7 1.3z"
      />
      <path
        fill={colors.main.tertiary}
        d="M487.3 335c2.1 0 4.1 1.1 5.2 3 1.7 2.9.7 6.5-2.2 8.2-.9.5-2 .8-3 .8-2.1 0-4.1-1.1-5.2-3-1.7-2.9-.7-6.5 2.2-8.2.9-.6 2-.8 3-.8m0-7c-2.3 0-4.5.6-6.5 1.7-3 1.7-5.2 4.5-6.1 7.9-.9 3.4-.4 6.9 1.3 9.9 2.3 4 6.6 6.5 11.3 6.5 2.3 0 4.5-.6 6.5-1.7 3-1.7 5.2-4.5 6.1-7.9.9-3.4.4-6.9-1.3-9.9-2.4-4-6.7-6.5-11.3-6.5z"
      />
      <path
        fill={dark ? colors.dark : colors.white}
        d="M369.4 418.5c-3.4 0-6.5-1.8-8.2-4.8-1.3-2.2-1.6-4.8-.9-7.2.7-2.5 2.2-4.5 4.4-5.8 1.4-.8 3.1-1.3 4.7-1.3 3.4 0 6.5 1.8 8.2 4.7 2.6 4.5 1.1 10.4-3.5 13-1.4 1-3 1.4-4.7 1.4z"
      />
      <path
        fill={colors.main.tertiary}
        d="M369.4 403c2.1 0 4.1 1.1 5.2 3 1.7 2.9.7 6.5-2.2 8.2-.9.5-2 .8-3 .8-2.1 0-4.1-1.1-5.2-3-1.7-2.9-.7-6.5 2.2-8.2 1-.5 2-.8 3-.8m0-7c-2.3 0-4.5.6-6.5 1.7-3 1.7-5.2 4.5-6.1 7.9-.9 3.4-.4 6.9 1.3 9.9 2.3 4 6.6 6.5 11.3 6.5 2.3 0 4.5-.6 6.5-1.7 3-1.7 5.2-4.5 6.1-7.9.9-3.4.4-6.9-1.3-9.9-2.3-4-6.6-6.5-11.3-6.5z"
      />
      <path
        fill={dark ? colors.dark : colors.white}
        d="M467.7 352.3c-31.9-55.3-75.4-89.9-97.1-77.4-21.7 12.5-13.4 67.5 18.5 122.8l55.7 5.8 22.9-51.2z"
      />
      <path
        fill={colors.main.tertiary}
        d="M433.5 355.7c10.7 2.9 17 13.8 14.1 24.5-2.9 10.7-13.8 17-24.5 14.1-10.7-2.9-17-13.8-14.1-24.5 2.9-10.6 13.9-17 24.5-14.1z"
      />
    </g>
  </svg>,
  <svg
    width={size}
    height={size}
    key="logo-2"
    viewBox="265 220 320 320"
    x="0px"
    y="0px"
  >
    <g id="react2" className="icon">
      <ellipse
        fill={colors.main.tertiary}
        cx="428.4"
        cy="375"
        rx="115.6"
        ry="45.4"
      />
      <path
        fill={colors.main.tertiary}
        d="M476 280.4c2.4 0 4.4.5 6.1 1.5 7.2 4.2 9.8 18.1 6.9 37.2-3.3 22.2-13.4 48.7-28.4 74.6-28.5 49.3-62.5 76-80 76-2.4 0-4.4-.5-6.1-1.5-7.2-4.2-9.8-18.1-6.9-37.2 3.3-22.2 13.4-48.7 28.4-74.6 28.5-49.4 62.5-76 80-76m0-8c-23.3 0-59.4 32.3-86.9 80-31.9 55.3-40.2 110.2-18.5 122.8 3 1.7 6.4 2.6 10.1 2.6 23.3 0 59.4-32.3 86.9-80 31.9-55.3 40.2-110.2 18.5-122.8-2.9-1.8-6.4-2.6-10.1-2.6z"
      />
      <g fill={colors.main.tertiary}>
        <path d="M317.3 362.5c-2.9 4-4.5 8.2-4.5 12.5s1.6 8.5 4.5 12.5v-25zM324.2 394.7c1.5 1.2 3.2 2.5 5 3.6v-46.6c-1.8 1.2-3.5 2.4-5 3.6v39.4zM341.2 345.2c-1.7.8-3.4 1.6-5 2.4v54.7c1.6.8 3.3 1.6 5 2.4v-59.5zM353.1 340.6c-1.7.6-3.4 1.2-5 1.8v65.3c1.6.6 3.3 1.2 5 1.8v-68.9zM365.1 337c-1.7.4-3.4.9-5 1.4v73.2c1.6.5 3.3.9 5 1.4v-76zM377 334.3c-1.7.3-3.4.7-5 1v79.2c1.6.4 3.3.7 5 1v-81.2zM389 332.3c-1.7.2-3.4.5-5 .8v83.8c1.6.3 3.3.5 5 .8v-85.4zM401 330.9c-1.7.2-3.3.3-5 .5v87.1c1.7.2 3.3.4 5 .5v-88.1zM412.9 330c-1.7.1-3.3.2-5 .3v89.3c1.7.1 3.3.2 5 .3V330zM424.9 329.7c-1.7 0-3.3.1-5 .1v90.5c1.7 0 3.3.1 5 .1v-90.7zM431.8 420.3c1.7 0 3.3-.1 5-.1v-90.5c-1.7 0-3.3-.1-5-.1v90.7zM448.8 330.3c-1.7-.1-3.3-.2-5-.3v90c1.7-.1 3.3-.2 5-.3v-89.4zM460.8 331.4c-1.7-.2-3.3-.4-5-.5v88.2c1.7-.2 3.3-.3 5-.5v-87.2zM472.7 333.1c-1.6-.3-3.3-.5-5-.8v85.3c1.7-.2 3.4-.5 5-.8v-83.7zM484.7 335.4c-1.6-.4-3.3-.7-5-1v81.3c1.7-.3 3.4-.7 5-1v-79.3zM496.6 338.4c-1.6-.5-3.3-.9-5-1.4v76c1.7-.4 3.4-.9 5-1.4v-73.2zM508.6 342.4c-1.6-.6-3.3-1.2-5-1.8v68.9c1.7-.6 3.4-1.2 5-1.8v-65.3zM520.6 347.6c-1.6-.8-3.3-1.6-5-2.4v59.5c1.7-.8 3.4-1.6 5-2.4v-54.7zM532.5 355.3c-1.5-1.2-3.2-2.5-5-3.6v46.6c1.8-1.2 3.5-2.4 5-3.6v-39.4zM539.5 387.5c2.9-4 4.5-8.2 4.5-12.5s-1.6-8.5-4.5-12.5v25z" />
      </g>

      <ellipse
        transform="rotate(-30 428.34 375.016)"
        fill={dark ? colors.dark : colors.white}
        cx="428.4"
        cy="375"
        rx="45.4"
        ry="115.6"
      />
      <path
        fill={colors.main.tertiary}
        d="M443.7 366.2c4.9 8.5 2 19.3-6.5 24.1-8.5 4.9-19.3 2-24.1-6.5-4.9-8.5-2-19.3 6.5-24.1 8.4-4.9 19.2-2 24.1 6.5z"
      />
    </g>
  </svg>,
  <svg
    width={size}
    height={size}
    key="logo-4"
    viewBox="265 220 320 320"
    x="0px"
    y="0px"
  >
    <g id="react4" className="icon">
      <path
        fill={colors.main.tertiary}
        d="M428.4 337.6c29.9 0 57.9 4.5 78.8 12.7 18 7.1 28.8 16.3 28.8 24.6s-10.8 17.6-28.8 24.6c-20.9 8.2-48.9 12.7-78.8 12.7s-57.9-4.5-78.8-12.7c-18-7.1-28.8-16.3-28.8-24.6s10.8-17.6 28.8-24.6c20.9-8.1 48.9-12.7 78.8-12.7m0-8c-63.8 0-115.6 20.3-115.6 45.4s51.7 45.4 115.6 45.4c63.8 0 115.6-20.3 115.6-45.4s-51.8-45.4-115.6-45.4z"
      />
      <path
        fill={colors.main.tertiary}
        d="M476 280.4c2.4 0 4.4.5 6.1 1.5 7.2 4.2 9.8 18.1 6.9 37.2-3.3 22.2-13.4 48.7-28.4 74.6-28.5 49.3-62.5 76-80 76-2.4 0-4.4-.5-6.1-1.5-7.2-4.2-9.8-18.1-6.9-37.2 3.3-22.2 13.4-48.7 28.4-74.6 28.5-49.4 62.5-76 80-76m0-8c-23.3 0-59.4 32.3-86.9 80-31.9 55.3-40.2 110.2-18.5 122.8 3 1.7 6.4 2.6 10.1 2.6 23.3 0 59.4-32.3 86.9-80 31.9-55.3 40.2-110.2 18.5-122.8-2.9-1.8-6.4-2.6-10.1-2.6z"
      />
      <path
        fill={dark ? colors.dark : colors.white}
        d="M380.7 280.4c9.3 0 22.6 7.3 36.5 20 15.3 13.9 30.7 33.8 43.5 55.9 14.9 25.9 25 52.4 28.4 74.6 2.9 19.1.3 33.1-6.9 37.2-1.7 1-3.7 1.5-6.1 1.5-9.3 0-22.6-7.3-36.5-20-15.3-13.9-30.7-33.8-43.5-55.9-14.9-25.9-25-52.4-28.4-74.6-2.9-19.1-.3-33.1 6.9-37.2 1.7-1.1 3.7-1.5 6.1-1.5m0-8c-3.7 0-7.2.8-10.1 2.6-21.7 12.5-13.4 67.5 18.5 122.8 27.5 47.6 63.6 80 86.9 80 3.7 0 7.2-.8 10.1-2.6 21.7-12.5 13.4-67.5-18.5-122.8-27.5-47.7-63.5-80-86.9-80z"
      />
      <path
        fill={colors.main.tertiary}
        stroke={colors.main.tertiary}
        strokeWidth="7"
        strokeMiterlimit="10"
        d="M411.5 365.3c3.5-6 9.9-9.7 16.9-9.7 7 0 13.4 3.7 16.9 9.7 3.5 6 3.5 13.5 0 19.5s-9.9 9.7-16.9 9.7c-7 0-13.4-3.7-16.9-9.7-3.5-6.1-3.5-13.5 0-19.5z"
      />
    </g>
  </svg>,
  <svg
    width={size}
    height={size}
    key="logo-5"
    viewBox="265 220 320 320"
    x="0px"
    y="0px"
  >
    <g id="react5" className="icon">
      <path
        fill={colors.main.tertiary}
        d="M428.4 337.6c29.9 0 57.9 4.5 78.8 12.7 18 7.1 28.8 16.3 28.8 24.6s-10.8 17.6-28.8 24.6c-20.9 8.2-48.9 12.7-78.8 12.7s-57.9-4.5-78.8-12.7c-18-7.1-28.8-16.3-28.8-24.6s10.8-17.6 28.8-24.6c20.9-8.1 48.9-12.7 78.8-12.7m0-8c-63.8 0-115.6 20.3-115.6 45.4s51.7 45.4 115.6 45.4S544 400.1 544 375s-51.8-45.4-115.6-45.4z"
      />
      <ellipse
        transform="rotate(30 428.376 374.975)"
        fill={colors.main.tertiary}
        cx="428.4"
        cy="375"
        rx="45.4"
        ry="115.6"
      />
      <g fill={dark ? colors.dark : colors.white}>
        <path d="M383.6 272.5c-4.9-.5-9.3.2-13.1 2.4s-6.6 5.6-8.6 10.1l21.7-12.5zM399 277.5c-1.9-1-3.8-1.8-5.6-2.5l-34.1 19.7c-.3 1.9-.5 4-.6 6.1l40.3-23.3zM410.6 284.6c-1.5-1.1-3.1-2.2-4.6-3.1l-47.4 27.4c.1 1.8.2 3.6.4 5.5l51.6-29.8zM420.6 292.6c-1.4-1.2-2.7-2.3-4-3.4L360 321.8c.3 1.7.6 3.5 1 5.2l59.6-34.4zM429.6 301.2c-1.2-1.3-2.5-2.5-3.7-3.6l-63.4 36.6c.4 1.7.9 3.3 1.3 5l65.8-38zM437.9 310.2c-1.1-1.3-2.3-2.6-3.4-3.8L365.9 346c.5 1.6 1 3.2 1.6 4.8l70.4-40.6zM445.6 319.6c-1.1-1.3-2.1-2.7-3.2-3.9l-72.6 41.9c.6 1.6 1.2 3.1 1.8 4.7l74-42.7zM452.8 329.2c-1-1.4-2-2.7-3-4.1l-75.4 43.6c.7 1.5 1.3 3.1 2 4.6l76.4-44.1zM459.6 339.1c-.9-1.4-1.8-2.8-2.8-4.2l-77.3 44.7c.7 1.5 1.5 3 2.2 4.5l77.9-45zM465.9 349.3c-.9-1.4-1.7-2.9-2.6-4.3l-78.4 45.2c.8 1.5 1.6 2.9 2.4 4.4l78.6-45.3zM471.8 359.7c-.8-1.5-1.6-2.9-2.4-4.4l-78.5 45.3c.9 1.4 1.7 2.9 2.6 4.3l78.3-45.2zM477.3 370.4c-.7-1.5-1.5-3-2.2-4.5l-77.9 45c.9 1.4 1.8 2.8 2.8 4.2l77.3-44.7zM482.3 381.3c-.7-1.5-1.3-3.1-2-4.6L404 420.8c1 1.4 2 2.7 3 4.1l75.3-43.6zM486.8 392.5c-.6-1.6-1.2-3.1-1.8-4.7l-73.9 42.7c1.1 1.3 2.1 2.7 3.2 3.9l72.5-41.9zM490.8 404c-.5-1.6-1-3.2-1.6-4.8l-70.4 40.7c1.1 1.3 2.3 2.6 3.4 3.8l68.6-39.7zM492.9 410.8l-65.8 38c1.2 1.3 2.5 2.5 3.7 3.6l63.4-36.6c-.4-1.6-.9-3.3-1.3-5zM496.8 428.2c-.3-1.7-.6-3.5-1-5.2l-59.6 34.4c1.4 1.2 2.7 2.3 4 3.4l56.6-32.6zM498.2 441.2c-.1-1.8-.2-3.6-.4-5.5l-51.6 29.8c1.5 1.1 3.1 2.2 4.6 3.1l47.4-27.4zM497.5 455.4c.3-1.9.5-4 .6-6.1l-40.4 23.3c1.9 1 3.8 1.8 5.6 2.5l34.2-19.7zM486.1 475.1c3.8-2.2 6.6-5.6 8.6-10.1L473 477.5c5 .5 9.4-.2 13.1-2.4z" />
      </g>
      <path
        fill={dark ? colors.dark : colors.white}
        d="M419.1 360c8.3-5.1 19.2-2.6 24.3 5.7 5.1 8.3 2.6 19.2-5.7 24.3-8.3 5.1-19.2 2.6-24.3-5.7-5.2-8.3-2.6-19.2 5.7-24.3z"
      />
    </g>
  </svg>,
  <svg
    width={size}
    height={size}
    key="logo-6"
    viewBox="265 220 320 320"
    x="0px"
    y="0px"
  >
    <g id="react6" className="icon">
      <g fill={colors.main.tertiary}>
        <path d="M317.3 362.5c-2.9 4-4.5 8.2-4.5 12.5s1.6 8.5 4.5 12.5v-25zM324.2 394.7c1.5 1.2 3.2 2.5 5 3.6v-46.6c-1.8 1.2-3.5 2.4-5 3.6v39.4zM341.2 345.2c-1.7.8-3.4 1.6-5 2.4v54.7c1.6.8 3.3 1.6 5 2.4v-59.5zM353.1 340.6c-1.7.6-3.4 1.2-5 1.8v65.3c1.6.6 3.3 1.2 5 1.8v-68.9zM365.1 337c-1.7.4-3.4.9-5 1.4v73.2c1.6.5 3.3.9 5 1.4v-76zM377 334.3c-1.7.3-3.4.7-5 1v79.2c1.6.4 3.3.7 5 1v-81.2zM389 332.3c-1.7.2-3.4.5-5 .8v83.8c1.6.3 3.3.5 5 .8v-85.4zM401 330.9c-1.7.2-3.3.3-5 .5v87.1c1.7.2 3.3.4 5 .5v-88.1zM412.9 330c-1.7.1-3.3.2-5 .3v89.3c1.7.1 3.3.2 5 .3V330zM424.9 329.7c-1.7 0-3.3.1-5 .1v90.5c1.7 0 3.3.1 5 .1v-90.7zM431.8 420.3c1.7 0 3.3-.1 5-.1v-90.5c-1.7 0-3.3-.1-5-.1v90.7zM448.8 330.3c-1.7-.1-3.3-.2-5-.3v90c1.7-.1 3.3-.2 5-.3v-89.4zM460.8 331.4c-1.7-.2-3.3-.4-5-.5v88.2c1.7-.2 3.3-.3 5-.5v-87.2zM472.7 333.1c-1.6-.3-3.3-.5-5-.8v85.3c1.7-.2 3.4-.5 5-.8v-83.7zM484.7 335.4c-1.6-.4-3.3-.7-5-1v81.3c1.7-.3 3.4-.7 5-1v-79.3zM496.6 338.4c-1.6-.5-3.3-.9-5-1.4v76c1.7-.4 3.4-.9 5-1.4v-73.2zM508.6 342.4c-1.6-.6-3.3-1.2-5-1.8v68.9c1.7-.6 3.4-1.2 5-1.8v-65.3zM520.6 347.6c-1.6-.8-3.3-1.6-5-2.4v59.5c1.7-.8 3.4-1.6 5-2.4v-54.7zM532.5 355.3c-1.5-1.2-3.2-2.5-5-3.6v46.6c1.8-1.2 3.5-2.4 5-3.6v-39.4zM539.5 387.5c2.9-4 4.5-8.2 4.5-12.5s-1.6-8.5-4.5-12.5v25z" />
      </g>
      <ellipse
        transform="rotate(-150 428.37 375.003)"
        fill={dark ? colors.dark : colors.white}
        cx="428.4"
        cy="375"
        rx="45.4"
        ry="115.6"
      />
      <path
        fill={dark ? colors.dark : colors.white}
        d="M380.7 280.4c9.3 0 22.6 7.3 36.5 20 15.3 13.9 30.7 33.8 43.5 55.9 14.9 25.9 25 52.4 28.4 74.6 2.9 19.1.3 33.1-6.9 37.2-1.7 1-3.7 1.5-6.1 1.5-9.3 0-22.6-7.3-36.5-20-15.3-13.9-30.7-33.8-43.5-55.9-14.9-25.9-25-52.4-28.4-74.6-2.9-19.1-.3-33.1 6.9-37.2 1.7-1.1 3.7-1.5 6.1-1.5m0-8c-3.7 0-7.2.8-10.1 2.6-21.7 12.5-13.4 67.5 18.5 122.8 27.5 47.6 63.6 80 86.9 80 3.7 0 7.2-.8 10.1-2.6 21.7-12.5 13.4-67.5-18.5-122.8-27.5-47.7-63.5-80.1-86.9-80z"
      />
      <path
        fill={colors.main.tertiary}
        d="M415.9 362.5c6.9-6.9 18.1-6.9 25 0 6.9 6.9 6.9 18.1 0 25-6.9 6.9-18.1 6.9-25 0-6.9-6.9-6.9-18.1 0-25z"
      />
    </g>
  </svg>,
]
