import styled from "styled-components";

import { colors } from "@globals";

export const LBtn = styled.a`
  display: flex;
  align-items: center;
  justify-content: left;
  text-align: left;
  outline: none;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
  color: ${colors.grade.yellow};
  width: 100%;
  background-color: transparent;

  span:first-child {
    color: ${colors.white};
    margin-right: 0.5rem;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 0.5em;
  font-size: 1.2em;
`;

export const Btn = styled.button`
  display: flex;
  align-items: center;
  justify-content: left;
  text-align: left;
  outline: none;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
  color: ${colors.grade.yellow};
  width: 100%;
  background-color: transparent;
  border: none;

  span:first-child {
    color: ${colors.white};
    margin-right: 0.5rem;
  }
`;