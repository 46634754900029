import speakers from "./speakers"

const getSpeaker = (name: string) =>
  speakers.data
    .filter(speaker => {
      return (speaker.name.toLowerCase() === name.toLowerCase()) && speaker
    })

export default {
  enabled: true,
  data: [
    {
      hour: "9h00",
      description: "Abertura",
    },
    {
      speaker: getSpeaker("Andrei Calazans")[0],
    },
    {
      speaker: getSpeaker("Carlos Ziegler")[0],
    },
    {
      speaker: getSpeaker("Talysson C.")[0],
    },
    {
      speaker: getSpeaker("Diego Haz")[0],
    },
    {
      speaker: getSpeaker("Laura Beatris")[0],
    },
    {
      hour: "12h00",
      description: "Almoço",
    },
    {
      hour: "13h00",
      description: "Abertura tarde",
    },
    {
      speaker: getSpeaker("Gabriel Daltoso")[0],
    },
    {
      speaker: getSpeaker("Frank Javier")[0],
    },
    {
      speaker: getSpeaker("Felipe Battistotti")[0],
    },
    {
      speaker: getSpeaker("Guilherme Rodz")[0],
    },
    {
      speaker: getSpeaker("Nicolas B./ Paulo E.")[0],
    },
    {
      speaker: getSpeaker("Antonio Gally")[0],
    },
    {
      speaker: getSpeaker("Zeno Rocha")[0],
    },
    {
      hour: "19h00",
      description: "Desconferência",
    },
    {
      hour: "19h30",
      description: "Encerramento",
    },
  ],
}
