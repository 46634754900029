import React, { ReactElement } from "react";

import { TypingEffect } from "@components/TypingEffect";

import {
  TitleContainer,
} from "./Title.styles";

interface IProps {
  index: number;
}

export const Title: React.FC<IProps> = ({ children, index }) => (
  <TypingEffect index={index}>
    <TitleContainer>
      <span>reactconf@2022</span>:<span>~</span><span>$</span> {children}
    </TitleContainer>
  </TypingEffect>
);
