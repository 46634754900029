import React, { ReactElement } from "react";
import { Title } from "../Title";

import {
  Container as StyledContainer,
  WrapperContainer,
} from "./Container.styles";

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  index: number;
  title: string;
}

export const Container: React.FC<IProps> = ({ children, title, index, ...props }) => (
  <StyledContainer {...props}>
    <Title index={index}>{title}</Title>
    <WrapperContainer>
      {children}
    </WrapperContainer>
  </StyledContainer>
);
