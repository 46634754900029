import Boticario from "@assets/images/sponsors/boticario.png"

export const emeraldSponsors = [
  {
    id: "boticario",
    name: "Grupo Boticário",
    image: Boticario,
    site: "https://www.grupoboticario.com.br/carreiras/",
  },
]

export const diamondSponsors = null
// [
  // {
  //   id: "gather",
  //   name: "Gather",
  //   image: Gather,
  //   site: "https://gather.town/",
  // },
  // {
  //   id: "legiti",
  //   name: "Legiti",
  //   image: Legiti,
  //   site: "https://www.legiti.com/",
  // },
  // {
  //   id: "vtex",
  //   name: "VTEX",
  //   image: VTEX,
  //   site: "https://vtex.com/br-pt/",
  // },
  // {
  //   id: "vanhack",
  //   name: "vanHack",
  //   image: VanHack,
  //   site: "https://vanhack.com/",
  // },
  // {
  //   id: "toptal",
  //   name: "TopTal",
  //   image: TopTal,
  //   site: "https://www.toptal.com/freelance-jobs",
  // },
// ]

export const mediumSponsors = null
// [
  // {
  //   id: "quanto",
  //   name: "Quanto",
  //   image: Quanto,
  //   site: "https://go.quan.to/vagas",
  // },
// ]

export const smallSponsors = null

export const extraSmallSponsors = null
