import styled, { keyframes, css } from "styled-components";

const typing = keyframes`
    from { width: 100% }
    to { width: 0 }
`;

const blink = keyframes`
    from { border-color: transparent }
    to { border-color: orange; }
`;

interface IProps {
    showCursor: boolean;
    delay: number;
    speed: number;
}

export const Container = styled.div<IProps>`
    width: 100%;
    display: flex;
    justify-content: flex-start;

    div {
        width: fit-content;

        .typewriter {
            width: 100%;
            overflow: hidden;
            position: relative;

            &::before {
                content: "";
                background: #111111;
                width: 100%;
                height: 100%;
                position: absolute;
                bottom: 0;
                right: 0;
                
                ${({speed, showCursor}) => speed && css`
                    animation: ${typing} ${speed}ms steps(20, end) forwards ${showCursor ? css`,${blink} .8s infinite` : ''};
                `}

                ${({delay}) => delay && css`
                    animation-delay: ${delay}ms;
                `}
                
                ${({showCursor}) => showCursor && css`
                    padding-right: -0.5rem;
                    border-left: .15rem solid orange;
                `}
            }            
        }
    }
`;