export const sessions = {
    home: true,
    conference: true,
    speakers: false,
    lineup: true,
    callforpapers: false,
    sponsors: true,
    support: true,
    community: true,
    contact: true,
    menu: false,
    footer: true,
    tickets: false,
    live: false
}