import styled from "styled-components";

export const Container = styled.div`
  width: 90vw;
  max-width: 1000px;
  padding: 2rem 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
  margin: auto;
`;

export const WrapperContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 98%;
  justify-content: left;
  text-align: left;
  padding-left: 2rem;
  margin-top: 1rem;
`;
